<template>
    <div>
        <treatment-history-detail
            backRoute="FranchiseNurseDetails"
            patientDetailRoute="FranchisePatientDetail"
        />
    </div>
</template>
<script>
import PatientTreatmentHistoryDetails from '../../general-component/treatment-history/TreatmentDetails'
export default {
    name:"FranchiePatientDetails",
    components:{
    'treatment-history-detail': PatientTreatmentHistoryDetails,
    },
    data(){
        return{

    }}
}
</script>